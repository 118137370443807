import { SubmitHandler, useForm } from 'react-hook-form';
import { HeadingUI4 } from '../../components/shared/Heading';
import { Button } from '../../components/shared/Button/Button';

import { InputField } from '../../components/shared/InputField/InputField';
import CloseIcon from '../../assets/close-icon.svg?react';
import { useEffect, useMemo, useState } from 'react';
import { useRootStore } from '../../stores/useRootStore';
import { Select } from '../../components/shared/Select';
import { observer } from 'mobx-react-lite';
import { AccompanyingGuest } from '../../apiMethods/getUserInfo';

type FormInput = {
  /** Для редактирования созданного гостя */
  id?: number;
  first_name: string;
  last_name: string;
  middle_name: string;
  relation: string;
};

type Guest = FormInput & {
  relation_degree: { id: number; name: string };
};

const nameRegExp = /^[a-zA-ZА-Яа-я\ёЁs]+$/;
const fields = [
  {
    name: 'last_name',
    label: 'Фамилия',
    type: 'text',
    placeholder: 'Иванов',
    registerOptions: {
      pattern: {
        value: nameRegExp,
        message: 'Недопустимый формат',
      },
    },
    autocomplete: 'name',
  },
  {
    name: 'first_name',
    label: 'Имя',
    type: 'text',
    placeholder: 'Иван',
    registerOptions: {
      pattern: {
        value: nameRegExp,
        message: 'Недопустимый формат',
      },
    },
    autocomplete: 'name',
  },
  {
    name: 'middle_name',
    label: 'Отчество',
    type: 'text',
    placeholder: 'Иванович',
    registerOptions: {
      pattern: {
        value: nameRegExp,
        message: 'Недопустимый формат',
      },
    },
    autocomplete: 'name',
  },
];

export const GuestForm = observer(
  ({
    onClose,
    guest,
    onGuestCreated,
  }: {
    guest?: Guest;
    onClose: () => void;
    onGuestCreated?: (guest: AccompanyingGuest) => void;
  }) => {
    const { authStore, bookingsStore } = useRootStore();

    const kinshipList = useMemo(() => {
      return bookingsStore.kinship.list?.map((k) => ({ ...k, label: k.name })) || [];
    }, [bookingsStore.kinship.list]);

    useEffect(() => {
      if (!guest || !kinshipList.length) {
        return;
      }

      const k = kinshipList.find((k) => k.id === guest?.relation_degree.id) ?? null;
      k && setKinship(k);
    }, [guest, kinshipList]);

    const [kinship, setKinship] = useState<null | { id: number; label: string; name: string }>(
      null,
    );

    const onKinshipChange = (index: number | null) => {
      const k = index !== null ? kinshipList[index] : null;
      setKinship(k);
    };

    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<FormInput>({ defaultValues: guest });

    useEffect(() => {
      if (bookingsStore.kinship.list) {
        return;
      }
      bookingsStore.loadKinshipTypes();
    }, [bookingsStore]);

    const onSubmit: SubmitHandler<FormInput> = async (data) => {
      const { id, first_name, last_name, middle_name } = data;
      const userId = authStore.user?.id;

      const guest_ = {
        userId,
        guestId: id || null,
        firstName: first_name,
        lastName: last_name,
        middleName: middle_name,
        kinship: kinship?.id || 0,
        // files: getFiles(),
      };

      // const action = id ? updateExtraGuest : createExtraGuest;
      const newGuest = await bookingsStore.createOrUpdateGuest(guest_);
      newGuest && onGuestCreated?.(newGuest)
      onClose();
    };

    return (
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 xl:space-y-8">
        <div className="flex items-center justify-between">
          <HeadingUI4 className="mb-0">Добавить гостя</HeadingUI4>
          <Button variant="link" style="secondary" className="" type="button" onClick={onClose}>
            <CloseIcon />
          </Button>
        </div>

        {fields.map((f) => (
          <InputField
            key={f.name}
            type={f.type}
            label={f.label}
            placeholder={f.placeholder}
            autoComplete={f.autocomplete}
            error={errors[f.name as keyof FormInput]?.message}
            {...register(f.name as keyof FormInput, f.registerOptions)}
          />
        ))}

        <div>
          <label htmlFor="" className="text-[16px] font-light uppercase text-neutral-900">
            Родство
          </label>
          <Select
            placeholder="Родство"
            value={kinship}
            options={kinshipList}
            onChange={onKinshipChange}
          >
            {kinshipList.map((k) => (
              <Select.Option key={k.id} label={k.name}></Select.Option>
            ))}
          </Select>
        </div>

        <div className="space-x-4">
          <Button type="submit">{guest?.id ? 'Изменить' : 'Добавить'}</Button>
          <Button style="secondary" variant="outlined" type="button" onClick={onClose}>
            Отмена
          </Button>
        </div>
      </form>
    );
  },
);
