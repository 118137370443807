import { SubmitHandler, useForm } from 'react-hook-form';
import { Field } from '../../components/forms/types';
import { Heading2, HeadingUI4 } from '../../components/shared/Heading';
import { InputField, TextAreaField } from '../../components/shared/InputField/InputField';
import { Checkbox } from '../../components/shared/Checkbox';
import { AnchorHTMLAttributes, useState } from 'react';
import { Button } from '../../components/shared/Button/Button';
import { BackLink } from './Rates';
import { useRootStore } from '../../stores/useRootStore';
import { observer } from 'mobx-react-lite';
import { useRedirectToHomePage } from '../../useRedirectToHomePage';
import { WithOrderSummaryLayout } from '../../components/WithOrderSummaryLayout';

type FormInput = {
  first_name: string;
  last_name: string;
  middle_name?: string;
  phone: string;
  email: string;
  city?: string;
  comment: string;
  agreed: boolean;
};

const nameRegExp = /^[a-zA-ZА-Яа-яёЁ\s]+$/;
const phoneRegExp = /^[+]?[1-9][(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
const emailRegExp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const getTextField = (
  field: string,
  label: string,
  placeholder: string,
  autocomplete = 'name',
) => ({
  name: field,
  label,
  type: 'text',
  placeholder,
  registerOptions: {
    required: {
      value: true,
      message: 'Это поле не должно быть пустым',
    },
    pattern: {
      value: nameRegExp,
      message: 'Недопустимый формат',
    },
  },
  autocomplete,
});

const fields: Field[] = [
  getTextField('last_name', 'Фамилия', 'Иванов*'),
  getTextField('first_name', 'Имя', 'Иван*'),
  {
    name: 'middle_name',
    label: 'Отчество',
    type: 'text',
    placeholder: 'Иванович',
    registerOptions: {
      pattern: {
        value: nameRegExp,
        message: 'Недопустимый формат',
      },
    },
    autocomplete: 'name',
  },
  {
    name: 'phone',
    label: 'Телефон',
    type: 'tel',
    placeholder: '+_(___)_______*',
    registerOptions: {
      required: {
        value: true,
        message: 'Это поле не должно быть пустым',
      },
      pattern: {
        value: phoneRegExp,
        message: 'Недопустимый формат',
      },
    },
    autocomplete: 'tel',
    pattern: /^00000$/,
  },
  {
    name: 'email',
    label: 'E-mail',
    type: 'email',
    placeholder: 'example@email.com*',
    autocomplete: 'email',
    registerOptions: {
      required: {
        value: true,
        message: 'Это поле не должно быть пустым',
      },
      pattern: {
        value: emailRegExp,
        message: 'Недопустимый формат',
      },
    },
  },
  // {
  //   name: 'city',
  //   label: 'Город',
  //   type: 'text',
  //   placeholder: 'Москва',
  //   registerOptions: {},
  // },
];

// const MaskedInput = forwardRef<HTMLInputElement, InputFieldProps>((props, ref) => {
//     const { ref: maskRef, unmaskedValue } = useIMask<HTMLInputElement>({ mask: '+0(000)000 00 00', lazy: false })
//     console.log(unmaskedValue)
//
//     return (
//         <InputField {...props} ref={r => {
//             maskRef.current = r;
//             (ref as (el: HTMLInputElement | null) => void)?.(r);
//         }} onChange={console.log} type="text" label="testl" placeholder="test" />
//     )
// })

export const UserForm = observer(() => {
  const { authStore, paramsStore, configStore } = useRootStore();
  const user = authStore.user;

  useRedirectToHomePage();

  const {
    register,
    handleSubmit,
    formState: { errors, isLoading, isSubmitting },
  } = useForm<FormInput>(
    user && {
      defaultValues: {
        first_name: user?.first_name,
        last_name: user?.last_name,
        middle_name: user?.middle_name,
        phone: user?.phone,
        email: user?.email,
      },
    },
  );

  const [isSubmitSucessful, setIsSubmitSuccessful] = useState<boolean | null>(null);

  const onSubmit: SubmitHandler<FormInput> = async data => {
    setIsSubmitSuccessful(null);
    const response = await paramsStore.submitReservation(data);
    response?.payload?.id && setIsSubmitSuccessful(true);

    const redirectUrl = response?.payload?.redirect_url;
    redirectUrl && window.location.replace(redirectUrl);
  };

  return (
    <WithOrderSummaryLayout>
      {configStore.shoudSkipServices ? (
        <BackLink to={'/rates'}>К выбору тарифа</BackLink>
      ) : (
        <BackLink to={'/services'}>К выбору услуг</BackLink>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className="my-8 space-y-8 3xl:mt-0">
        <Heading2 className="mt-0">Данные гостя</Heading2>
        {fields.map(f => (
          <InputField
            key={f.name}
            type={f.type}
            label={f.label}
            placeholder={f.placeholder}
            autoComplete={f.autocomplete}
            error={errors[f.name as keyof FormInput]?.message}
            {...register(f.name as keyof FormInput, f.registerOptions)}
          />
        ))}

        <TextAreaField
          label="Комментарий"
          placeholder="Ваше сообщение"
          {...register('comment')}
          error={errors['comment']?.message}
        />

        <fieldset className="flex">
          <Checkbox
            id="agreement"
            {...register('agreed', {
              required: { value: true, message: 'Необходимо дать согласие' },
            })}
          />
          <label className="cursor-pointer pl-2 text-sm text-neutral-700" htmlFor="agreement">
            <span>Я подтверждаю свое согласие c </span>
            {configStore.policyLink && (
              <Anchor target="_blank" href={configStore.policyLink}>
                Политикой в отношении обработки персональных данных
              </Anchor>
            )}
            .{' '}
            {configStore.rulesAndServicesLink && (
              <Anchor target="_blank" href={configStore.rulesAndServicesLink}>
                С правилами и договором оферты
              </Anchor>
            )}
          </label>
        </fieldset>
        <div>
          {errors['agreed'] && (
            <span className="mt-2 text-sm text-critical-300">{errors['agreed'].message}</span>
          )}
        </div>

        <fieldset>
          <HeadingUI4>Варианты оплаты</HeadingUI4>
          <p className="mb-5 text-sm font-light text-neutral-700">
            Для оплаты (ввода реквизитов Вашей карты) Вы будете перенаправлены на платёжный шлюз ПАО
            СБЕРБАНК
          </p>
          {paramsStore.rateMode === 'CORP' && (
            <p className="mb-5 text-sm font-light text-neutral-700">
              Расчет и подтверждение дополнительных скидок к базовому тарифу в зависимости от грейда
              или условий оплаты осуществляются отдельно, после этапа бронирования, но не ранее 45
              календарных дней до даты заезда.{' '}
            </p>
          )}
          <div className="space-y-3 xl:flex xl:space-x-4 xl:space-y-0">
            {configStore.paymentMethods?.map(payment => (
              <Button
                key={payment.id}
                style="accent"
                variant="filled"
                type="submit"
                className="w-full whitespace-nowrap xl:w-fit "
                onClick={() => paramsStore.setPayment(payment)}
                disabled={isLoading || isSubmitting || isSubmitSucessful || false}
              >
                {payment.payment_type}
              </Button>
            ))}
          </div>
          {/* <div className="mt-4 space-y-3 xl:flex xl:space-x-4 xl:space-y-0">
            <Button className="w-full whitespace-nowrap xl:w-fit">Оплатить всю стоимость</Button>
            <Button style="primary" variant="outlined" className="w-full xl:w-fit">
              Оплатить первую ночь
            </Button>
          </div> */}
        </fieldset>
      </form>
    </WithOrderSummaryLayout>
  );
});

const Anchor = ({ children, ...props }: AnchorHTMLAttributes<HTMLAnchorElement>) => (
  <a className="text-primary-300 underline" {...props}>
    {children}
  </a>
);
